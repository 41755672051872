import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'santojob-service-header',
  templateUrl: './service-header.component.html',
  styleUrls: ['./service-header.component.css']
})
export class ServiceHeaderComponent implements OnInit {

  @Input() orientation: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.orientation){
      this.orientation = "left";
    }
  }

}
