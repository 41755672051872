<section id="us" class="container-fluid full-screen">
    <div class="container" style="height: 70vh;">
        <div class="row d-flex align-items-center justify-content-center" style="height: 25vh;">
            <h1>Quem somos</h1>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="d-flex align-items-center justify-content-center">
                    <span class="profile-picture"><img src="assets/images/joao-neto.png" /></span>
                </div>
            </div>
            <div class="col-md-6 align-items-center wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                <p class="us-title">João Neto</p>
                <p class="us-job">Gerente de Atendimento e de Novos Negócios</p>
                <div class="col-md-8 d-flex align-items-center">
                    <p class="us-description">Formado em Administração com especialização em marketing e técnicas de negociação, atuou nas praças de Salvador atendendo todo Nordeste, Brasília junto as regiões Norte e Centro-Oeste e São Paulo com demandas para o Sul e Sudeste realizando campanhas de âmbito nacional.</p>
                </div>
                <img src="assets/images/brazil-map.png" class="us-image" />
            </div>
        </div>
    </div>
    <div class="container-fluid" style="background-color: #0093D8; height: 30vh;">
        <div class="container d-flex align-items-center h-100">
            <div class="col-md-8" style="font-family: Futura; font-size: 18px; color: #FFF;">
                <p>Uma agência moderna, irreverente e com personalidade própria. Chegamos para quebrar conceitos, inovar e trazer o novo para nossos clientes.
                    Atualmente com sede na cidade de São Paulo e com parceiros capacitados para atender todo Brasil a Santo Job Comunicação conta com uma equipe jovem e experiente para realizar trabalhos de comunicação integrada com base em três pilares: criatividade, comprometimento e qualidade.
                    Como diretor de atendimento e de novos negócios João Neto possui mais de 14 anos de experiência com vivência em comunicação empresarial, marketing, trade marketing, marketing de influência, eventos corporativos de pequenos e grandes portes, publicidade online, off-line.</p>
            </div>
        </div>
        
    </div>
</section>
