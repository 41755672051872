import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logos-e-selos',
  templateUrl: './logos-e-selos.component.html',
  styleUrls: ['./logos-e-selos.component.css']
})
export class LogosESelosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
