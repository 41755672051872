import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {

  arrayPictures: string[];

  constructor() {}

  ngOnInit(): void {
    this.arrayPictures = ['eventos-carnaval-2014/carnaval-da-boa-2014-ambev-publico.jpg', 'eventos-carnaval-2014/carnaval-da-boa-2014-ambev-fantasias.jpg', 'eventos-carnaval-2014/carnaval-da-boa-2014-ambev-banda.jpg'];
  }

}
