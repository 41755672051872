import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acessoria-de-imprensa',
  templateUrl: './acessoria-de-imprensa.component.html',
  styleUrls: ['./acessoria-de-imprensa.component.css']
})
export class AcessoriaDeImprensaComponent implements OnInit {

  arrayPictures: string[];

  constructor() {}

  ngOnInit(): void {
    this.arrayPictures = ['tirulipa.png', 'tirulipa.png', 'tirulipa.png'];
  }

}
