import { Component, HostListener } from '@angular/core';
import { slideInAnimation } from './route-animation';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ slideInAnimation ]
})

export class AppComponent {

  @HostListener('window:scroll') onScroll(e: Event): void {
    this.showMenu();
    this.activeMenu();
  }

  homeActive: boolean = false;
  
  constructor() {
  }

  prevScrollpos = window.pageYOffset;
  vhmax = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  showMenu(): void {
    let currentScrollPos = window.pageYOffset;
    document.getElementById("menu").style.top = "-100%";
    if (currentScrollPos >= (3 * this.vhmax)) {
        document.getElementById("menu").style.top = "0";
    }
    this.prevScrollpos = currentScrollPos;
  }

  activeMenu(): void {
    let scroll = $(window).scrollTop();
    $(".nav-link").removeClass("active");
    if (scroll >= this.vhmax && scroll < (4 * this.vhmax) )  {
        $("#nav-us").children('a').addClass("active");
    } else if (scroll >= (4 * this.vhmax) && scroll < (5 * this.vhmax) )  {
        $("#nav-doing").children('a').addClass("active");
    } else if (scroll >= (5 * this.vhmax) && scroll < (6 * this.vhmax) )  {
        $("#nav-insta").children('a').addClass("active");
    } else if (scroll >= (6 * this.vhmax) )  {
        $("#nav-contact").children('a').addClass("active");
    }
  }

  title = 'santojobcomunicacao';
}
