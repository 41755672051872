<section class="container-fluid background-gradient full-screen wow fadeIn">
    <div class="down-background h-100">
        <div class="container align-items-center">
            <div class="row align-items-center flex-row-reverse">
                <div class="col wow fadeInLeftSmall" style="visibility: visible; animation-name: fadeInLeftSmall;">
                    <div class="cornerup"><img src="assets/images/efect-cornerup.png" class="cornerup-img" /></div>
                    <p class="curiosity-title">CURIOSIDADE</p>
                    <p class="curiosity-description"> a oração do publicitário</p>
                    <div class="curiosity">
                        Pai nosso que estais no céu, <br>
                        Santificado seja o vosso cliente. <br>
                        Venha a nós a vossa agência, <br>
                        E seja feito o vosso briefing. <br>
                        Assim no brainstorm como no insight. <br>
                        A criação nossa de cada dia nos dai hoje. Perdoai as nossas más idéias, <br>
                        Assim como nós perdoamos o anúncio que não tenha vendido, <br>
                        não nos deixe cair em má comunicação, mas livrai-nos do Conar! <br>
                    </div>
                    <img src="assets/images/amem.png" class="image-amem">
                    <div class="curiosity">* Autor Desconhecido</div>
                </div>
                <div class="col cloud-md-row wow fadeInRightSmall" style="visibility: visible; animation-name: fadeInRightSmall;">
                    <div class="row align-items-center justify-content-center">
                        <img src="assets/images/cloud-md.png" class="cloud-md" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <santojob-slider-brands></santojob-slider-brands>
</section>