import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incentivo',
  templateUrl: './incentivo.component.html',
  styleUrls: ['./incentivo.component.css']
})
export class IncentivoComponent implements OnInit {

  arrayPictures: string[];

  constructor() {}

  ngOnInit(): void {
    this.arrayPictures = ['tirulipa.png', 'tirulipa.png', 'tirulipa.png'];
  }

}
