import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'santojob-slider-picture',
  templateUrl: './slider-picture.component.html',
  styleUrls: ['./slider-picture.component.css']
})
export class SliderPictureComponent implements OnInit {

  @Input() images: string[];
  @Input() type: string;

  customOptions: OwlOptions = {
    nav : false, // Show next and prev buttons
    navText: ['Previous', 'Next'],
    navSpeed : 300,
    margin: 15,
    items : 1,
    touchDrag: true,
    pullDrag: true,
    freeDrag: true,
    lazyLoad: true,
    dots: true,
    loop: false,
  }
  

  constructor() {
  }

  ngOnInit(): void {
    //console.log(this.images);
    //console.log(this.type);
  }

}
