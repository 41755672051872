import { Component, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'santojob-slider-brands',
  templateUrl: './slider-brands.component.html',
  styleUrls: ['./slider-brands.component.css']
})
export class SliderBrandsComponent implements OnInit {

  customOptions: OwlOptions = {
    nav : true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    navSpeed : 300,
    items : 4, 
    touchDrag: true,
    pullDrag: false,
    freeDrag: false,
    lazyLoad: true,
    dots: false,
    loop: false,
    responsive:{
      0:{
          items: 1
      },
      600:{
          items: 3
      },
      1000:{
          items: 4
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
