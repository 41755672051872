<nav id="menu" class="navbar navbar-expand-md fixed-top" style="top: -100%">
    <div class="container d-flex">
        <div class="col">
            <a class="navbar-brand" href="#"><img src="assets/images/logo-santo-job-new.png" class="logo-menu"></a>
        </div>
        <div id="nav-us" class="col">
            <a class="nav-link active" href="#us">Quem somos</a>
        </div>
        <div id="nav-doing" class="col">
            <a class="nav-link" href="#doing">O que fazemos</a>
        </div>
        <div id="nav-insta" class="col">
            <a class="nav-link" href="#influencers">Influencers</a>
        </div>
        <div id="nav-contact" class="col">
            <a class="nav-link" href="#footer">Fale Conosco</a>
        </div>
    </div>
</nav>