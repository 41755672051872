import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promocao',
  templateUrl: './promocao.component.html',
  styleUrls: ['./promocao.component.css']
})
export class PromocaoComponent implements OnInit {

  arrayPictures: string[];

  constructor() {}

  ngOnInit(): void {
    this.arrayPictures = ['tirulipa.png', 'tirulipa.png', 'tirulipa.png'];
  }

}
