<div class="d-flex align-items-stretch justify-content-center display-top">
    <div class="d-flex align-items-center justify-content-center top-brand-{{orientation}}">
        <img src="assets/images/logo-santo-job-new.png" class="logo-small">
    </div>
    <div class="container-fluid d-flex align-items-stretch justify-content-end h-100">
        <div class="d-flex flex-row{{ (orientation=='left')? '-reverse' : '' }} align-items-center w-100 menu-{{orientation}}">
            <div class="d-flex justify-content-end {{ (orientation=='right')? 'mr-auto' : '' }} back-{{orientation}}">
                <a [routerLink]="['/']" class="router-link">
                    <div class="arrow-box">
                        <span class="arrow left"></span>
                    </div>
                </a>
            </div>
            <div class="d-flex flex-column {{ (orientation=='left')? 'mr-auto' : '' }} align-items-center justify-content-{{ (orientation=='left')? 'start' : 'end' }}" style="margin-top: -40px;">
                <div class="d-flex align-items-center justify-content-{{ (orientation=='left')? 'start' : 'end' }} w-100">
                    <img src="{{icon}}" class="icon-display" /><h2>{{subtitle}}</h2>
                </div>
                <div class="d-flex align-self-baseline justify-content-{{ (orientation=='left')? 'start' : 'end' }} w-100">
                    <h1>{{title}}</h1>
                </div>
            </div>
        </div>
        <div class="col-md-7 d-flex align-items-center justify-content-{{ (orientation=='left')? 'start' : 'end' }} description-box-{{orientation}}">
            <span class="description-{{orientation}}">{{description}}</span>
        </div>
    </div>
</div>