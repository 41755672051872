<div style="position: relative; background-color: #FFF; height: 25vh; top: -25vh;">
    <div class="container d-flex justify-content-center align-items-center">
        <span class="partner-title">Parceiros</span>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100">
        <owl-carousel-o [options]="customOptions" class="slider-picture container d-flex justify-content-center align-items-center h-100">
            <ng-template carouselSlide>
                <div class="slide slider-picture d-flex justify-content-center align-items-center">
                    <img src="assets/images/partner-01.png" class="partner-brand" />
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide slider-picture d-flex justify-content-center align-items-center">
                    <img src="assets/images/partner-02.png" class="partner-brand" />
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide slider-picture d-flex justify-content-center align-items-center">
                    <img src="assets/images/partner-03.png" class="partner-brand" />
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide slider-picture d-flex justify-content-center align-items-center">
                    <img src="assets/images/partner-04.png" class="partner-brand" />    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide slider-picture d-flex justify-content-center align-items-center">
                    <img src="assets/images/partner-05.png" class="partner-brand" />
                </div>
            </ng-template>    
        </owl-carousel-o>
    </div>
</div>