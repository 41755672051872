import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { PublicidadeComponent } from './pages/publicidade/publicidade.component';
import { IncentivoComponent } from './pages/incentivo/incentivo.component';
import { ServicesComponent } from './pages/services/services.component';
import { PlanejamentoEstrategicoComponent } from './pages/planejamento-estrategico/planejamento-estrategico.component';
import { TradeMarketingComponent } from './pages/trade-marketing/trade-marketing.component';
import { InfluencerComponent } from './pages/influencer/influencer.component';
import { LogosESelosComponent } from './pages/logos-e-selos/logos-e-selos.component';
import { PromocaoComponent } from './pages/promocao/promocao.component';
import { PublicidadeDigitalComponent } from './pages/publicidade-digital/publicidade-digital.component';
import { ProjetosProprietariosComponent } from './pages/projetos-proprietarios/projetos-proprietarios.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { EventosSkolComponent } from './pages/eventos-skol/eventos-skol.component';
import { EndomarketingComponent } from './pages/endomarketing/endomarketing.component';
import { AcessoriaDeImprensaComponent } from './pages/acessoria-de-imprensa/acessoria-de-imprensa.component';


const routes: Routes = [
  { path: '', component: HomeComponent, data: {animation: 'Home'} },
  { path: 'publicidade', component: PublicidadeComponent, data: {animation: 'About'} },
  { path: 'incentivo', component: IncentivoComponent },
  { path: 'servicos', component: ServicesComponent },
  { path: 'planejamento-estrategico', component: PlanejamentoEstrategicoComponent },
  { path: 'trade-marketing', component: TradeMarketingComponent },
  { path: 'influencer', component: InfluencerComponent },
  { path: 'logos-e-selos', component: LogosESelosComponent },
  { path: 'promocao', component: PromocaoComponent },
  { path: 'publicidade-digital', component: PublicidadeDigitalComponent },
  { path: 'projetos-proprietarios', component: ProjetosProprietariosComponent },
  { path: 'eventos', component: EventosComponent },
  { path: 'eventos-skol', component: EventosSkolComponent },
  { path: 'endomarketing', component: EndomarketingComponent },
  { path: 'acessoria-de-imprensa', component: AcessoriaDeImprensaComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
