import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'santojob-curiosity',
  templateUrl: './curiosity.component.html',
  styleUrls: ['./curiosity.component.css']
})
export class CuriosityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
