import { Component, OnInit, Input } from '@angular/core';
import { IContent } from '../../IContent';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'santojob-slider-content',
  templateUrl: './slider-content.component.html',
  styleUrls: ['./slider-content.component.css']
})
export class SliderContentComponent implements OnInit {

  @Input() items: IContent[];

  constructor() {
  }

  customOptionsContent: OwlOptions = {
    nav : false, // Show next and prev buttons
    navText: ['Previous', 'Next'],
    navSpeed : 300,
    margin: 15,
    items : 1,
    touchDrag: true,
    pullDrag: true,
    freeDrag: true,
    lazyLoad: true,
    dots: true,
    loop: false,
  }

  ngOnInit(): void {
    //console.log(this.items);
  }

}
