import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgwWowModule } from 'ngx-wow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ServicesComponent } from './pages/services/services.component';
import { InfluencersComponent } from './components/influencers/influencers.component';
import { CuriosityComponent } from './components/curiosity/curiosity.component';
import { FooterComponent } from './components/footer/footer.component';
import { SliderHomeComponent } from './components/slider-home/slider-home.component';
import { SliderPictureComponent } from './components/slider-picture/slider-picture.component';
import { SliderBrandsComponent } from './components/slider-brands/slider-brands.component';
import { ParallaxDirective } from './parallax.directive';
import { IncentivoComponent } from './pages/incentivo/incentivo.component';
import { PlanejamentoEstrategicoComponent } from './pages/planejamento-estrategico/planejamento-estrategico.component';
import { TradeMarketingComponent } from './pages/trade-marketing/trade-marketing.component';
import { InfluencerComponent } from './pages/influencer/influencer.component';
import { LogosESelosComponent } from './pages/logos-e-selos/logos-e-selos.component';
import { PromocaoComponent } from './pages/promocao/promocao.component';
import { PublicidadeDigitalComponent } from './pages/publicidade-digital/publicidade-digital.component';
import { ProjetosProprietariosComponent } from './pages/projetos-proprietarios/projetos-proprietarios.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { EventosSkolComponent } from './pages/eventos-skol/eventos-skol.component';
import { EndomarketingComponent } from './pages/endomarketing/endomarketing.component';
import { AcessoriaDeImprensaComponent } from './pages/acessoria-de-imprensa/acessoria-de-imprensa.component';
import { PublicidadeComponent } from './pages/publicidade/publicidade.component';
import { ServiceHeaderComponent } from './components/service-header/service-header.component';
import { SliderContentComponent } from './components/slider-content/slider-content.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SliderHomeComponent,
    AboutUsComponent,
    ServicesComponent,
    InfluencersComponent,
    FooterComponent,
    CuriosityComponent,
    SliderPictureComponent,
    SliderBrandsComponent,
    ParallaxDirective,
    HomeComponent,
    IncentivoComponent,
    PlanejamentoEstrategicoComponent,
    TradeMarketingComponent,
    InfluencerComponent,
    LogosESelosComponent,
    PromocaoComponent,
    PublicidadeDigitalComponent,
    ProjetosProprietariosComponent,
    EventosComponent,
    EventosSkolComponent,
    EndomarketingComponent,
    AcessoriaDeImprensaComponent,
    PublicidadeComponent,
    ServiceHeaderComponent,
    SliderContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgwWowModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
