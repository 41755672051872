<section id="doing" class="container-fluid background-gradient full-screen">
    <div class="d-flex flex-column align-items-center justify-content-center w-100 m-0">
        <santojob-service-header [orientation]="['right']" [icon]="['assets/images/icons/icon-press.png']" [title]="['Público Alvo']" [subtitle]="['Acessoria de Imprensa']" [description]="['Exagero de Giga Tasty por Tirullipa em suas redes sociais']"></santojob-service-header>
        <div class="container wow fadeIn">
            <div class="d-flex align-items-center justify-content-start w-100">
                <div class="col-md-4 d-flex align-items-top justify-content-center w-100 p-0" style="padding-right: 50px; top: -60px;">
                    <santojob-slider-picture [images]="arrayPictures"></santojob-slider-picture>
                </div>
                <div class="d-flex flex-column align-items-stretch justify-content-end">
                    <div class="row align-items-end">
                        <p style="color: white; font-family: Futura; font-size: 16px; padding-left: 100px;">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
    Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo conse
                        </p>
                    </div>
                    <div class="row align-items-center wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                        <a [routerLink]="['/']" class="router-link">
                            <div class="display-box justify-content-center" style="height: 35px; padding: 5px 30px 5px 30px; margin-left: 100px;">
                                <p>Solicitar Orçamento</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>