import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publicidade-digital',
  templateUrl: './publicidade-digital.component.html',
  styleUrls: ['./publicidade-digital.component.css']
})
export class PublicidadeDigitalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
