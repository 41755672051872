import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgwWowService } from 'ngx-wow';


@Component({
  selector: 'santojob-slider-home',
  templateUrl: './slider-home.component.html',
  styleUrls: ['./slider-home.component.css']
})
export class SliderHomeComponent implements OnInit {

  stepIndex = 0;
  
  @ViewChild('dreams')
  dreams: ElementRef;

  @ViewChild('miracles')
  miracles: ElementRef;
  
  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }

  ngOnInit(): void {
  }

  public nextStep(): void {
    this.stepIndex++;
    if (this.stepIndex == 1) {
      this.dreams.nativeElement.scrollIntoView();
    } else {
      this.miracles.nativeElement.scrollIntoView();
    }
  }

}
