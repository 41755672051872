<section id="doing" class="container-fluid background-gradient full-screen">
    <div class="d-flex flex-column align-items-center justify-content-center w-100 m-0">
        <div class="row align-items-stretch justify-content-center display-top w-100" >
            <santojob-service-header [icon]="['assets/images/icons/icon-logos-selos.png']" [title]="['Logomarcas']" [subtitle]="['Logos e Selos']" [description]="['Exagero de Giga Tasty por Tirullipa em suas redes sociais']"></santojob-service-header>
        </div>
        
        <div class="container d-flex flex-row align-items-center justify-content-start" style="height: 70vh;">
            <div class="d-flex flex-column align-items-center justify-content-start">
                <p style="color: white; font-family: Futura; font-size: 16px; padding-right: 100px;">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo conse
                </p>
                <div class="d-flex align-items-center justify-content-start wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                    <a [routerLink]="['/']" class="router-link">
                        <div class="display-box justify-content-center" style="height: 35px; padding: 5px 30px 5px 30px; margin-left: 10px;">
                            <p>Solicitar Orçamento</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-end w-100 p-0" style="padding-right: 50px; top: -60px;">
                <img src="assets/images/tirulipa.png" class="picture1of2" />
                <img src="assets/images/tirulipa.png" class="picture2of2" />
            </div>
        </div>
        
    </div>
</section>