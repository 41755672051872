<owl-carousel-o [options]="customOptionsContent" class="row align-items-center align-self-end justify-content-end slider-content">
    <ng-template carouselSlide *ngFor='let item of items'>
        <div class="container slide-content item-content wow fadeIn">
            <div class="row align-items-center justify-content-start w-100">
                <div class="col-md-8 align-items-center justify-content-center">
                    <p style="color: white; font-family: Futura; font-size: 16px; padding-right: 100px;">
                        {{item.text}}
                    </p>
                    <div class="row align-items-center wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                        <a [routerLink]="['/']" class="router-link">
                            <div class="display-box justify-content-center" style="height: 35px; padding: 5px 30px 5px 30px; margin-left: 10px;">
                                <p>Solicitar Orçamento</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center w-100 p-0" style="padding-right: 50px; top: -60px;">
                    <santojob-slider-picture [images]="item.imagesUrl" [type]="item.typeSlider"></santojob-slider-picture>
                </div>
            </div>
        </div>
    </ng-template>
</owl-carousel-o>