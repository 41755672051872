<section id="doing" class="container-fluid full-screen">
    <div class="container wow fadeIn pt-5">
        <div class="row align-items-center justify-content-center h-100 pt-5">
            <div class="row align-items-center justify-content-center pt-5">
                <h1>O que fazemos ?</h1>
            </div>
            <div class="row justify-content-center align-items-center wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                <div class="row" style="margin-top: 50px;">
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./publicidade']" class="link">
                            <div class="item-doing-primary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing">
                                    <img src="assets/images/icons/icon-publicidade.png"/>
                                </div>
                                <div class="d-flex align-items-start justify-content-center">
                                    <span class="text-doing">Publicidade</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./incentivo']" class="link">
                            <div class="item-doing-secondary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing-dark">
                                    <img src="assets/images/icons/icon-incentivo.png" />
                                </div>
                                <div class="d-flex align-items-start justify-content-center">
                                    <span class="text-doing-dark">Incentivo</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./planejamento-estrategico']" class="link">
                            <div class="item-doing-primary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing">
                                    <img src="assets/images/icons/icon-planejamento.png"/>
                                </div>
                                <div class="d-flex align-items-start justify-content-center">
                                    <span class="text-doing">Planejamento<br>Estratégico</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./trade-marketing']" class="link">
                            <div class="item-doing-secondary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing-dark">
                                    <img src="assets/images/icons/icon-trade.png" />
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing-dark">Trade Marketing</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./influencer']" class="link">
                            <div class="item-doing-primary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing">
                                    <img src="assets/images/icons/icon-influencer.png"/>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing">Talentos</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./logos-e-selos']" class="link">
                            <div class="item-doing-secondary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing-dark">
                                    <img src="assets/images/icons/icon-logos-selos.png" />
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing-dark">Logos e Selos</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="row" style="margin-top: 50px;">
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./promocao']" class="link">
                            <div class="item-doing-secondary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing-dark">
                                    <img src="assets/images/icons/icon-promotion.png" />
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing-dark">Promoção</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./publicidade-digital']" class="link">
                            <div class="item-doing-primary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-top justify-content-center icon-doing">
                                    <img src="assets/images/icons/icon-publi-digital.png" style="margin-top: 15px;"/>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing">Publi Digital</p>
                                </div>  
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./projetos-proprietarios']" class="link">
                            <div class="item-doing-secondary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing-dark">
                                    <img src="assets/images/icons/icon-project.png" style="margin-top: 5px;"/>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing-dark">Projetos<br> Proprietários</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./eventos']" class="link">
                            <div class="item-doing-primary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing">
                                    <img src="assets/images/icons/icon-event.png" />
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing">Eventos</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./endomarketing']" class="link">
                            <div class="item-doing-secondary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing-dark">
                                    <img src="assets/images/icons/icon-endomarketing.png" />
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing-dark">Endomarketing</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <a [routerLink]="['./acessoria-de-imprensa']" class="link">
                            <div class="item-doing-primary d-flex align-items-center justify-content-center">
                                <div class="d-flex align-items-center justify-content-center icon-doing" style="margin-top: 15px;">
                                    <img src="assets/images/icons/icon-press.png"/>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="text-doing">Assessoria de Imprensa</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>