import { Component, OnInit } from '@angular/core';
import { Title, Meta   } from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private titleService:Title, private metaService:Meta) {
    this.titleService = titleService;
    this.metaService = metaService;
  }

  ngOnInit(): void {
    this.titleService.setTitle("Santo Job Comunicação");
    this.metaService.addTag({name: 'description', content: 'Santo Job Comunicação'}, true); 
    this.metaService.addTag({charset: 'UTF-8'}); 
    this.metaService.addTag({httpEquiv: 'Content-Type', content: 'text/html'}); 
    this.metaService.addTag({property: 'og:title', content: "My Text"}); 
    this.metaService.addTag({property: 'og:type', content: "website"}); 
    this.metaService.addTag({name: 'robots', content: 'INDEX, FOLLOW'});
  }

}
