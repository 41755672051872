<section id="influencers" class="container-fluid full-screen">
    <div class="container wow fadeIn h-100 pt-5">
        <div class="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
            <div class="d-flex align-items-center justify-content-center pt-5">
                <h1>Influencers</h1>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-around w-100 p-5">
                <div class="influencers-box d-flex align-items-center justify-content-center">
                    <img src="assets/images/icons/old-influencer.png" />
                </div>
                <div class="influencers-box-invert d-flex align-items-center justify-content-center">
                    <img src="assets/images/icons/woman-influencer.png" />
                </div>
                <div class="influencers-box d-flex align-items-center justify-content-center">
                    <img src="assets/images/icons/boy-influencer.png" />
                </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center p-5 wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                <p class="influencer-description">Trabalhar com produtores de conteúdo, conhecidos como influenciadores digitais, tem como objetivo criar uma ponte entre sua marca e o público influenciado por eles, impactando positivamente na sua estratégia de marketing digital. A Santo Job Comunicação seleciona esse time com embasamento nos perfis que estarão aptos para trabalhar sua marca de acordo com a estratégia desenvolvida pela agência e cliente. É importante analisar o tamanho da audiência, engajamento com essa audiência e a relevância para o nicho de mercado que seu cliente atingir para ter bons resultados.</p>
                <div class="d-flex display-box justify-content-center" style="height: 35px; padding: 5px 30px 5px 30px; margin-left: 10px;">
                    <p>Solicitar Orçamento</p>
                </div>
            </div>
        </div>
    </div>
</section>