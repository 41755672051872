import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos-skol',
  templateUrl: './eventos-skol.component.html',
  styleUrls: ['./eventos-skol.component.css']
})
export class EventosSkolComponent implements OnInit {

  arrayPictures: string[];

  constructor() {}

  ngOnInit(): void {
    this.arrayPictures = ['eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1079.jpg', 'eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1736.jpg', 'eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1037.jpg', 'eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1052.jpg', 'eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1048.jpg', 'eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1043.jpg', 'eventos-casa-conceito-skol-ultra/campeonato-wakesurf-skol-ultra-1238.jpg'];
  }

}
