<!-- <div [@rollUpDown]="stepIndex" class="full-screen"> -->

    <section class="container-fluid background-gradient full-screen">
        <div class="top-background wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
            <div class="container">
                <div class="container row justify-content-center align-items-center wow fadeIn" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s; animation-duration: 0.5s;">
                    <div class="cloud-sm">
                        <img src="assets/images/cloud-md.png">
                    </div>
                    <div class="cloud-lg">
                        <img src="assets/images/cloud-lg.png">
                    </div>
                    <div class="cloud-md">
                        <img src="assets/images/cloud-md.png">
                    </div>	
                    <div class="d-flex w-100" style="margin-top: 25vh;">
                        <div class="align-items-center mx-auto my-auto">
                            <img src="assets/images/logo-santo-job-new.png" class="logo">
                        </div>			
                    </div>
                </div>
                <div class="row justify-content-center align-items-center wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s; margin-top: -20px; margin-bottom: 50px;">
                    <div class="display-box pr-5 pl-5">
                        <div class="icons-menu row justify-content-center align-items-center h-100">
                            <div><a href="http://www.facebook.com/santojobcomunicacao/" target="_blank" style="color: #FFFFFF;"><i class="fab fa-facebook-f icons-menu" aria-hidden="true"></i></a></div>
                            <div><a href="http://www.instagram.com/santojobcomunicacao" target="_blank" style="color: #FFFFFF;"><i class="fab fa-instagram icons-menu" aria-hidden="true"></i></a></div>
                            <div><a href="http://www.linkedin.com/company/santojobcomunicacao" target="_blank" style="color: #FFFFFF;"><i class="fab fa-linkedin icons-menu" aria-hidden="true"></i></a></div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center animate-bounce">
                    <p><a class="arrow down arrow-button" (click)="nextStep()"></a></p>
                </div>
            </div>
        </div>
    </section>

    <section #dreams class="container-fluid full-screen">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
            <div class="container d-flex flex-column justify-content-center align-content-stretch h-100">
                <div class="d-flex flex-row-reverse w-100 p-5 wow fadeIn" style="visibility: visible; animation-name: fadeIn; animation-duration: 0.5s;">
                    <img src="assets/images/logo-santo-job-new.png" class="logo-small">
                </div>
                <div class="d-flex flex-column align-self-center align-items-left pt-5 pb-5">
                    <div class="welcome-more">seja muito</div>
                    <div class="welcome-text align-items-left">
                        <div class="welcome">bem-vindo</div>
                        <div class="conerup"><img src="assets/images/efect-cornerup.png" /></div>
                    </div>
                    <div class="display-box wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                        <p>ao nosso universo particular</p>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-center align-items-center p-5 animate-bounce">
                    <p><a class="arrow down arrow-button" (click)="nextStep()"></a></p>
                </div>
            </div>
        </div>
    </section>

    <section #miracles class="container-fluid background-gradient full-screen">
        <div class="down-background d-flex flex-row align-items-start justify-content-center h-100">
            <div class="wow fadeIn" style="visibility: visible; animation-name: fadeIn; animation-duration: 0.1s; animation-delay: 1s; position: absolute; top: 200%; left: 0; width: 60vw; height: 80vh; opacity: 0.5; background-image: url('assets/images/effect-light.png'); background-size: 100%; background-repeat: no-repeat;">
                <p> </p>
            </div>
            <div class="container d-flex flex-column align-items-start justify-content-between">
                <div class="magic">
                    <img src="assets/images/magic.png" class="magic-img">
                </div>
                <div class="miracle-title d-flex align-items-start justify-content-start">
                    <img src="assets/images/miracles.png" class="miracles">
                </div>
                <div class="miracle-white d-flex justify-content-left wow bounce" style="visibility: visible; animation-name: bounce; animation-delay: 0.2s;">
                    <p>mas chegamos bem perto!</p>
                </div>      
            </div>
            <div class="d-flex align-items-stretch p-5 wow fadeIn" style="visibility: visible; animation-name: fadeIn; animation-duration: 0.5s;">
                <img src="assets/images/logo-santo-job-new.png" class="logo-small">
            </div>  
            
            
        </div>
    </section>

<!-- </div> -->