<footer id="footer" class="container-fluid p-0">
    <div class="container pt-3">
        <div class="row row-50 justify-content-center justify-content-lg-start flex-lg-row align-items-center py-5">
            <div class="col-sm-10 col-lg-6 wow fadeInLeftSmall" style="visibility: visible; animation-name: fadeInLeftSmall;">
                <img src="assets/images/logo-santo-job-white.png" class="logo-footer">
                <p class="footer-title">Contato</p>

                <div class="footer-link">
                    <p><img src="assets/images/icons/icon-phone.png" class="form-icon"/><a href="tel:+551196452-0400">+ 55 11 96452-0400</a></p>
                    <p><img src="assets/images/icons/icon-email.png" class="form-icon"/><a href="mailto:contato@santojobcomunicacao.com">contato@santojobcomunicacao.com</a></p>
                    <p><img src="assets/images/icons/icon-instagram.png" class="form-icon"/><a href="intagram.com/santojobcomunicacao">@santojobcomunicacao.com</a></p>
                </div>
                <img src="assets/images/icons/icon-location.png" style="width: 43px;"/>
                <p style="font-size: 14px;">Avenida Paulista 171, 4º andar <br> Bela Vista, São Paulo - SP <br>  CEP 01311-904</p>
            </div>
            <div class="col-lg-1"></div>
            <div class="footer-box col-sm-10 col-lg-5 wow fadeInRightSmall" style="visibility: visible; animation-name: fadeInRightSmall;">
                <span class="form-title">Fale Conosco</span>
                <form class="form-contact">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <input type="text" name="name" class="form-control" placeholder="Nome" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <input type="text" name="email" class="form-control" placeholder="E-mail" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <input type="text" name="phone" class="form-control" placeholder="Telefone" />
                        </div>
                        <div class="form-group col-md-6">
                            <input type="text" name="subject" class="form-control" placeholder="Assunto" />
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" name="message" id="exampleFormControlTextarea1" rows="3" placeholder="Mensagem"></textarea>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="submit" class="form-button btn">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row justify-content-center align-items-bottom">
            <p class="copyright">© 2020 Santo Job. Todos os direitos reservados.</p>
        </div>
    </div>       
</footer>