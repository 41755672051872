import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['./influencer.component.css']
})
export class InfluencerComponent implements OnInit {

  arrayPictures: string[];

  constructor() { }

  ngOnInit(): void {
    this.arrayPictures = ['magazine.jpeg', 'tirulipa.png', 'tirulipa.png'];
  }

}
